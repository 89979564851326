import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/Seo/"
import MainLayout from "../components/Layout/MainLayout"
import Section from "../components/Layout/Section"
import ContentBox from "../components/ContentBox/"
import QuoteBanner from "../components/Banner/QuoteBanner"
import * as LINKS from "../constants/links"
import ReggaeDivider from "../components/ReggaeDivider/"

export const data = graphql`
  query getAboutContent {
    hero: contentfulHeroImages {
      about {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    images: contentfulAboutSeite {
      aboutOneImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      aboutThreeImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    text1: contentfulAboutSeiteAboutOneTextNode {
      childMdx {
        body
      }
    }
    text2: contentfulAboutSeiteAboutTwoTextNode {
      childMdx {
        body
      }
    }
    text3: contentfulAboutSeiteAboutThreeTextNode {
      childMdx {
        body
      }
    }
  }
`

const AboutPage = ({ location, data }) => {
  const {
    hero: {
      about: { fluid },
    },
  } = data
  return (
    <MainLayout location={location} imageSrc={fluid} title="About">
      <SEO title="About" />
      <Section title="Eine Sendung entsteht">
        <ContentBox
          text={data.text1.childMdx.body}
          image={data.images.aboutOneImage.fluid}
        />
      </Section>
      <ReggaeDivider />
      <Section title="It's all about Reggae Music" secondBG>
        <ContentBox
          text={data.text2.childMdx.body}
          buttonText="Alle Sendungen"
          route={LINKS.PODOMATIC}
        />
      </Section>
      <QuoteBanner />
      <Section title="Roots Operator">
        <ContentBox
          text={data.text3.childMdx.body}
          image={data.images.aboutThreeImage.fluid}
        />
      </Section>
    </MainLayout>
  )
}

export default AboutPage

import React from 'react'
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles(theme => ({
    backgroundImage: {
        height: "100%",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
      backgroundOverlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        width: "100%",
        height: "100%",
        display: "flex",
    },
}))

const Banner = ({children, imageSrc}) => {
    const classes = useStyles()
    return (
        <BackgroundImage fluid={imageSrc} className={classes.backgroundImage}>
            <div className={classes.backgroundOverlay}>
                <Container maxWidth="lg">
                    {children}
                </Container>
            </div>
        </BackgroundImage>
    )
}

export default Banner

import React, { Fragment } from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Button } from "@material-ui/core"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

const useStyles = makeStyles(theme => ({
  textWrapper: {
    order: "1",
  },
  text: {
    opacity: "0.9",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  buttonWrapper: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  button: {
    borderColor: theme.palette.primary.main,
    margin: "3rem auto 0 auto",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
}))

const ContentBox = ({ text, image, route, buttonText }) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Grid container spacing={8}>
        {image && (
          <Grid item xs={12} sm={12} md={6}>
            <Zoom>
              <Image fluid={image} style={{ marginTop: "1rem" }} />
            </Zoom>
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={image ? 6 : 12}
          classes={{ root: classes.textWrapper }}
        >
          <Fade>
            <Typography
              variant="body1"
              component="span"
              classes={{ root: classes.text }}
            >
              <MDXRenderer>{text}</MDXRenderer>
            </Typography>
          </Fade>
          {buttonText && route && (
            <div className={classes.buttonWrapper}>
              <Button variant="outlined" classes={{ root: classes.button }}>
                <Link to={route} target="_blank" rel="noreferrer">
                  {buttonText}
                </Link>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default ContentBox

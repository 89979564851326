import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import Banner from '../Layout/Banner'

const useStyles = makeStyles(theme => ({
    content: {
        padding: "5rem 0"
    },
    quote: {
        fontWeight: "700",
        padding: "1rem 0",
        fontStyle: "italic"
    },
    credit: {
        fontStyle: "italic",
        opacity: "0.8"
    },
    icon: {
        fontSize: "3rem",
        opacity: "0.8"
    }
}))

const query = graphql`
  query getQuoteBannerBG {
    file(relativePath: { eq: "guitar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`


const QuoteBanner = () => {
    const classes = useStyles()
    const { file: { childImageSharp: { fluid }}} = useStaticQuery(query)
    return (
        <Banner imageSrc={fluid}>
            <div className={classes.content}>
                <Typography variant="h4" component="h4" classes={{ root: classes.quote }} align="center">
                    One good thing about music, when it hits you, you feel no pain.
                </Typography>
                <Typography variant="h5" component="h5" align="center" classes={{ root: classes.credit}}>
                    Bob Marley
                </Typography>
            </div>
        </Banner>
    )
}

export default QuoteBanner
